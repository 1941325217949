.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #143642;
    height: 80px;
}

.navbar-logo a {
    text-decoration: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    height: 10%;
    padding: 20px;
    color: white;
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
}

.navbar-logo img {
    width: 4rem; 
    height: auto; 
    transition: transform 0.3s ease; 
    padding-left: 1rem;
    padding-right: 1rem;
}


.navbar-links {
    list-style: none;
    display: flex;
    height: 40%;
    align-items: center;
}

.navbar-links li{
    display: flex;
    height: 100%;
    align-items: center;
}

.navbar-links li a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    display: flex;
    height: 100%;
    padding: 0 0.8rem;
    position: relative;
    transition: color 0.3s, text-shadow 0.3s;
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
}

.navbar-links li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    bottom: -3px;
    left: 50%;
    background-color: #fff;
    transition: width 0.3s, left 0.3s;
}

.navbar-links li a:hover {
    color: #59C9A5;
    transform: scale(1.18);
}

.navbar-links li a:hover::after {
    width: 100%;
    left: 0;
}
