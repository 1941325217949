*{
  margin: 0;
  padding: 0;
  font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
}

.description {
 padding-top: 200px;
 padding-bottom: 150px;
 text-align: center;
 font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
 max-width: 600px;
 margin: auto;
}

.description::before,
.description::after {
  content: "";
  display: block;
  height: 1px;
  width: 50px;
  background-color: #000; /* Change this color as needed */
  margin: 10px auto;
}

.description::before {
  margin-top: -20px; /* Adjust this value as needed */
}

.description::after {
  margin-bottom: -20px; /* Adjust this value as needed */
}

.About {
  color: blue;
}

button {
  background-color: #143642;
  color: white;
  height: 50px;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
  border: none
}

button:hover {
  background-color: #1436426e;
  color: #143642;
}

h1{
  text-align: center;
}

.spacing{
  padding-bottom: 2rem;
}
