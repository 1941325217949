.shell{
    width: 75%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: #E0F5EF;
    text-align: center;
    padding: 0rem;
    font-size: 2rem;
    font-weight: bold;
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;


}

.mission{
    width: 75%;
    margin: auto;
    text-align: center;
    padding: 1.2rem;
    font-size: 1.5rem;
    font-weight: normal;
    text-decoration: none;
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;

}

.innershell{ /* our team */
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
}

.slideAbout{
    padding: 2.5rem;
    height: 450 px;
}

.rounded {
    height: 225px; 
    justify-self: center;
    justify-items: center;
    align-items: center; 
    justify-content: center;
    display: flex;
}

.image {
    margin-left: auto;
    margin-right: auto;
    height: 200px; 
    width: 200px; 
    object-fit: cover;
}

.text{
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
    display: flex;
    font-size: 1.2rem;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    color: #000000;
    text-decoration: none;
    gap: 10px;
    padding: 4px;
    font-size: large;
    font-weight: initial;

}

.name{
    font-weight: bold;
    font-size: large;
    justify-content: center;
    justify-items: center;
}

.button{
    width: 110px;
    background: white;
    font-size: large;
    pad: 6px;
    border-radius: 9999px;
}

.button:hover{
    transform: scale(1.08);
}



