.wholething{
    text-align: center;
    max-width: 900px;
    margin: auto;
}
.cardbasics {
    display: flex;
    padding-top: 2rem;
    align-items: center; /* Horizontally center the content */
    text-align: center;
    flex-direction: column;
}

.basicsinfo {
    text-align: left;
    width: 75%;
    padding: 2rem;
    font-size: large;
}

.rb {
    display: flex;
    padding-top: 2rem;
    align-items: center; /* Horizontally center the content */
    text-align: center;
    flex-direction: column;
}

.innerrb {
    text-align: left;
    width: 75%;
    padding: 2rem;
    font-size: large;
}

.cardcomp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    padding: 4rem;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text within the container */
}

.card img {
    display: block;
    width: 60%; 
    height: auto;
    margin-bottom: 1rem; 
}

.card p {
    text-align: center; /* Ensure the card name is centered */
    margin-bottom: 1rem; /* Space below the card name */
}

.card ul {
    list-style-type: disc; 
    padding-left: 0; /* Remove padding to center bullets */
    margin: 0;
    text-align: left;
    font-size: large;
}

.card ul li {
    display: list-item;
    text-align: left; /* Align text within list items to the left */
    width: fit-content; /* Adjust width to fit content */
}


.final{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    padding-bottom: 5rem;
}


.thoughts{
    width: 75%;
    padding-top: 2rem;
    text-align: left;
    font-size: large;
}

.othercards{
    padding-top: 2rem;
    font-size: large; 
}

.othercards li{
    display: list-item;
    text-align: left; /* Align text within list items to the left */
    list-style-type: none;

}