.ageShell {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center; /* Horizontally center the content */
    text-align: center; /* Center text within the container */
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
}

.intro::before {
    content: "";
    display: block;
    height: 1px;
    width: 100px;
    background-color: #000; /* Change this color as needed */
    margin: 10px auto;
    margin-bottom: 3rem;
}

.intro {
    max-width: 75%; /* Optional: set a max-width for better readability */
    margin: 0 auto; /* Center the intro text block within its container */
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem; 
    padding: 4rem;
}

.grid-item img {
    width: 100%; 
    height: auto; 
    display: block; 
}

.grid-item:hover{
    transform: scale(1.015);
}