.ageShell {
    display: block;
    text-align: left; 
    margin: auto;
    max-width: 1000px;
}

.text {
    text-align: left;
    max-width: 700px;
    margin: auto;
}

h1 {
    padding-top: 50px;
    padding-bottom: 50px;
}

p {
    padding-bottom: 25px;
}

.services {
    padding-left: 50px;
}

.links {
    padding-left: 50px;
    color: #143642;
}

.learnHeader {
    padding-top: 50px;
    text-align: center;
    font-size: 50px;
}