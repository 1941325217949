.ageShell{
    font-size: x-large;
    display: flex;
    justify-content: left;
    align-items: left;
}

.services{
    text-align: center;
    font-size: large;
    margin: auto;
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem; 
    padding: 4rem;
}

.grid-item img {
    width: 100%; 
    height: auto; 
    display: block; 
}

.grid-item:hover{
    transform: scale(1.015);
}