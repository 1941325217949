.footer {
    background-color: #143642;
    min-height: 200px;
    padding: 100px;
    margin-top: 50px;
    height: auto;
}

.footer-links {
    list-style: none;
    display: flex;
}

.footer-links li{
    display: flex;
    height: 100%;
    align-items: center
}

.footer-links li a {
    position: relative;
    display: flex;
    text-decoration: none;
    color: white;
    padding: 0 0.8rem;
}

.footer-links li a:hover {
    color: #59C9A5;
}